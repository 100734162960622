import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '../lib/styles';
// import UserHeader from '../components/user-header';
import Layout from '../components/layout';

import PaymentStats from '../components/payment_stats';
import AgentEvents from '../components/agent_events';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Layout headerTitle="Dashboard">
      <div className={classes.container}>
        <div>
          <h2>Payout stats</h2>
          <PaymentStats/>
        </div>
        <div>
          <h2>Agent Events</h2>
          <AgentEvents/>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
