import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { pick } from 'lodash';
import downloadCsv from '../lib/local-csv';
import { Button } from '../lib/styles';

function Download(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(Object.keys(props.rows[0] || {}));
  const allColumns = Object.keys(props.rows[0] || {}).sort();
  return (
    <IconButton
      {...props}
      aria-label="download"
      onClick={() => {
        setDialogOpen(true);
      }}
    >
      <DownloadIcon fontSize="inherit" />
      {dialogOpen ? (
        <Dialog
          open={dialogOpen}
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle>Download to CSV</DialogTitle>
          <div>
            <div>
              <Button
                onClick={() => {
                  setSelectedColumns(allColumns);
                }}
                style={{ margin: '10px' }}
              >
                Select All
              </Button>
              <Button
                onClick={() => {
                  setSelectedColumns([]);
                }}
                style={{ margin: '10px' }}
              >
                Select None
              </Button>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
              }}
            >
              {allColumns.map((column, idx) => (
                <div key={idx} style={{ margin: '5px' }}>
                  <input
                    type="checkbox"
                    checked={selectedColumns.includes(column)}
                    onChange={() => {
                      if (selectedColumns.includes(column)) {
                        setSelectedColumns(selectedColumns.filter((c) => c !== column));
                      } else {
                        setSelectedColumns([...selectedColumns, column]);
                      }
                    }}
                  />
                  {column}
                </div>
              ))}
            </div>
            <div>
              <div style={{ float: 'right' }}>
                {/* <Button
                  onClick={() => {
                    console.log('closing');
                    setDialogOpen(false);
                    return true;
                  }}
                  style={{ margin: '10px' }}
                >
                  Close
                </Button> */}
                <Button
                  onClick={async () => {
                    const selectedRows = props.rows.map((row) => pick(row, selectedColumns));
                    await downloadCsv(selectedRows, props.name, props.type);
                    setDialogOpen(false);
                  }}
                  style={{ margin: '10px' }}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      ) : ''}
    </IconButton>
  );
}

export default Download;
