import React, { useState, useEffect, useMemo } from 'react';
import {
  capitalize,
  startCase,
  camelCase,
  omit,
} from 'lodash';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import config from '../config';
import DownloadButton from '../components/download-csv';
// import UserHeader from '../components/user-header';
import Layout from '../components/layout';
import { Select, Button } from '../lib/common';

const DEFAULT_URL = '/payments?orderBy=created_at&limit=5000';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'carrier_name',
    headerName: 'Carrier',
    align: 'left',
    width: 150,
  },
  {
    field: 'policy_number',
    headerName: 'Policy #',
    align: 'left',
    width: 150,
  },
  {
    field: 'client_name',
    headerName: 'Client',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>${Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'full_business_split',
    headerName: 'SH Business',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>${Number(params.row.full_business_split).toFixed(2)}</span>;
    },
  },
  {
    field: 'reference',
    headerName: 'Reference',
    align: 'right',
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
  {
    field: 'edit',
    headerName: 'Edit',
    align: 'center',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/payments/${params.row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>;
    },
  },
  {
    field: 'account_num',
    headerName: 'Acc. Num',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.account_num) {
        return 'Y';
      }
      return <span style={{ color: 'red' }}>N</span>;
    },
  },
  {
    field: 'routing_num',
    headerName: 'Routing',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.routing_num) {
        return 'Y';
      }
      return <span style={{ color: 'red' }}>N</span>;
    },
  },
  {
    field: 'new_business',
    headerName: 'New Business',
    align: 'center',
    width: 110,
    renderCell: (params) => {
      return <span>{capitalize(params.value)}</span>;
    },
  },
];

const filteredColumns = (filter) => [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'count',
    headerName: 'Records',
    align: 'right',
    width: 120,
  },
  {
    field: 'total_amount',
    headerName: 'Agent Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      const totalAmount = Number(params.row.total_amount);
      if (!Number.isNaN(totalAmount)) {
        return <span>${totalAmount.toFixed(2)}</span>;
      }
      return <span>N/A</span>;
    },
  },
  {
    field: 'total_business',
    headerName: 'Total Business',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      const totalBusiness = Number(params.row.total_business);
      if (!Number.isNaN(totalBusiness)) {
        return <span>${totalBusiness.toFixed(2)}</span>;
      }
      return <span>N/A</span>;
    },
  },
  {
    field: 'created',
    headerName: capitalize(filter),
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created ? params.row.created.substring(0, 10) : ''}</span>;
    },
  },
];

const cleanData = (data, filtered = false) => {
  if (filtered) {
    return (data || []).map((p, id) => {
      return {
        ...p,
        id,
        total_amount: Number(p.total_amount),
        total_business: Number(p.total_business),
      };
    });
  }

  return (data || []).map((p, idx) => {
    return {
      ...p,
      id: p.id || idx,
      amount: Number(p.amount),
    };
  });
};

const Payments = () => {
  const classes = useStyles();
  const params = useParams();
  const { userId } = params;

  const [err] = useState(null);
  const [status, setStatus] = useState('all');
  const [personal, setPersonal] = useState('all');
  const [type, setType] = useState('all');
  const [joint, setJoint] = useState('all');
  const [filter, setFilter] = useState('all');
  const [cols, setCols] = useState(columns);
  const [csvName, setCsvName] = useState('payments');
  const [url, setUrl] = useState(
    userId ? `/payments?orderBy=created_at&limit=5000&agent=${userId}` : DEFAULT_URL,
  );
  // let user;
  // if (userId) {
  //   const { data } = useSWR(`/users/${userId}`);
  //   user = data;
  // }

  const { data: payments, loading: paymentsLoading } = useSWR(url);
  // const dlName = user ? `${user.firstname}_${user.lastname}_${user.num}_payments` : 'payments';

  let cleanPayments = useMemo(() => cleanData(payments || [], filter !== 'all'), [payments, filter]);
  cleanPayments = (cleanPayments || []).map((p) => {
    const agent_id = p.agent;
    const newP = omit(p, ['agent', 'client_name']);
    newP.agent_id = agent_id;
    return newP;
  });

  useEffect(() => {
    switch (filter) {
      case 'all':
        setUrl(DEFAULT_URL);
        setCols(columns);
        setCsvName('payments');
        break;
      case 'week':
      case 'month':
        setUrl(`/payments/${filter}?orderBy=created&limit=5000`);
        setCols(filteredColumns(filter));
        setCsvName(`payments-${filter}ly`);
        break;
      default:
        break;
    }
  }, [filter]);

  useEffect(() => {
    const params = {
      personal, status, joint, type,
    };

    if (filter === 'all') {
      let update = '';
      if (userId) {
        update += `&agent=${userId}`;
      }
      for (const param in params) {
        if (params[param] !== 'all') {
          update += `&${param}=${params[param]}`;
        }
      }
      setUrl(`${DEFAULT_URL}${update}`);
    }
  }, [filter, status, personal, joint, type, userId]);

  return (
    <Layout headerTitle={
      `Agent Payments${
        filter === 'all'
          ? ''
          : ` - ${startCase(camelCase(` by ${filter}`))}`
      }`
    }
    >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          <Box gridColumn="span 3">
            <Select
              label="Filter"
              value={filter}
              name="filter"
              onChange={(evt) => {
                setFilter(evt.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="week">View By Week</MenuItem>
              <MenuItem value="month">View By Month</MenuItem>
            </Select>
          </Box>
          {filter === 'all' ? (
            <>
              <Box gridColumn="span 3">
                <Select
                  label="Status"
                  value={status}
                  name="status"
                  onChange={(evt) => {
                    setStatus(evt.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="OPEN">Open</MenuItem>
                  <MenuItem value="PAID">Paid</MenuItem>
                </Select>
              </Box>
              <Box gridColumn="span 3">
                <Select
                  label="Type"
                  value={type}
                  name="type"
                  onChange={(evt) => {
                    setType(evt.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="LIFE">Life</MenuItem>
                  <MenuItem value="ANNUITY">Annuity</MenuItem>
                  <MenuItem value="HEDGEHOG">Hedgehog</MenuItem>
                  <MenuItem value="LANDING_ROCK">Landing Rock</MenuItem>
                </Select>
              </Box>
              <Box gridColumn="span 3">
                <Select
                  label="Personal"
                  value={personal}
                  name="personal"
                  onChange={(evt) => {
                    setPersonal(evt.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="true">Personal</MenuItem>
                  <MenuItem value="false">Non-Personal</MenuItem>
                </Select>
              </Box>
              <Box gridColumn="span 3">
                <Select
                  label="Splits?"
                  value={joint}
                  name="joint"
                  onChange={(evt) => {
                    setJoint(evt.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="true">Joint</MenuItem>
                  <MenuItem value="false">Single Agent</MenuItem>
                </Select>
              </Box>
            </>
          ) : ''}
          {cleanPayments && cleanPayments.length ? (
            <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
              <DownloadButton rows={cleanPayments} name={csvName} />
            </Box>
          ) : (
            ''
          )}
        </Box>
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={cols} rows={cleanPayments} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
      <Button sx={{ margin: '10px' }} href={`${config.API_URL}/payments/nacha`}>
        Download ACH
      </Button>
      <Button sx={{ margin: '10px' }} href="/upload/receipt">
        XML Records
      </Button>
      <Button sx={{ margin: '10px' }} href="/agent_1099">
        Agent 1099
      </Button>
    </Layout>
  );
};

export default Payments;
